<template>
    <div style="height: 600px;">
        <div style="line-height: 35px;margin-left: 20px;">结算账户余额：<span style="color:#F69403;">{{accountBalance.acct_balance}}</span><span style="color: #2FB8FD;"> (可用余额 + 冻结余额)</span></div>
        <div style="line-height: 35px;margin-left: 20px;">可用余额：<span style="color:#F69403;">{{accountBalance.avl_balance}}</span><span style="color: #2FB8FD;"> (该余额大于0时，才可发起取现)</span></div>
        <div style="line-height: 35px;margin-left: 20px;">冻结余额：<span style="color:#F69403;">{{accountBalance.frz_balance}}</span><span style="color: #2FB8FD;"> (当配置了自动结算功能，在每日发起结算时会将可用余额转为冻结金额。该部分金额不允许取现)</span></div>
        <div style="line-height: 35px;margin-left: 20px;">昨日日终余额:<span style="color:#F69403;">{{accountBalance.last_avl_balance}}</span></div>
        <div style="line-height: 35px;margin-left: 20px;">当前结算户名:<a href="#" @click="dialogVisible = true">{{accountBalance.cardName}}</a> 行号:<span  style="color: #FF9800;cursor: pointer;;">{{accountBalance.cardNo}}</span></div>
        <div style="margin: 20px 0 20px 20px;">
            <el-button @click="startcreatePay">发起支付</el-button>
            <el-button @click="starttransferMoney">发起转账</el-button>
            <el-button @click="starttransFer">发起分账</el-button>
            <el-button @click="startCash">发起提现</el-button>
        </div>

        <!--        发起支付-->
        <div v-if="createPay == true">
            <div  style="cursor: pointer;width: 260px;color: #3d97d4;line-height: 50px;text-align: center;margin-left: 20px;">
                <el-input type="number" placeholder="输入金额" v-model="payAmt" clearable></el-input>
            </div>
            <div style="margin-left: 20px;margin-top: 20px;">
                <el-button  type="primary" @click="submitCreatePay">确定支付</el-button>
            </div>
        </div>

        <!--        发起转账-->
        <div v-if="transferMoney == true">
            <div  style="cursor: pointer;width: 580px;color: #3d97d4;line-height: 50px;text-align: center;margin-left: 20px;">
                <div style="float: left;">
                    <el-input type="" placeholder="输入对方手机号" v-model="mobile" clearable></el-input>
                </div>
                <div style="float: left;margin-left: 20px;">
                    <el-button @click="checkedMoile">搜索</el-button>
                </div>
            </div>
            <div  style="float: left;width: 100%;margin-left: 20px;">
                <template>
                    <el-radio-group v-model="radio" @change="agreeChange">
                        <el-radio v-for="(item,index) in user" :key="index" :label="item.uuid">{{item.nickName}}</el-radio>
                    </el-radio-group>
                    <div v-if="user.length > 0" style="padding-top: 20px;">
                        <span style="color: #F89D56;">昵称:</span>{{account.nickName}}
                        <span style="color: #F89D56;">真实姓名:</span>{{account.realName}}
                        <span style="color: #F89D56;">账户名称:</span>{{account.bankName}}
                    </div>
                </template>
            </div>
            <div  style="cursor: pointer;width: 260px;color: #3d97d4;line-height: 50px;text-align: center;margin-left: 20px;">
                <el-input type="number" placeholder="输入金额" v-model="payAmt" clearable></el-input>
            </div>
            <div style="margin-left: 20px;margin-top: 20px;">
                <el-button  type="primary" @click="corpTransferMoneyDetermine">确定转账</el-button>
            </div>
        </div>


        <!--        发起分账-->
        <div v-if="transFer == true">
            <div  style="cursor: pointer;width: 260px;color: #3d97d4;line-height: 50px;text-align: center;margin-left: 20px;">
                <el-input type="number" placeholder="输入金额" v-model="payAmt" clearable></el-input>
            </div>
            <div style="margin-left: 20px;margin-top: 20px;">
                <el-button  type="primary" @click="divisionAccountsDetermine">确定分账1</el-button>
            </div>
        </div>

        <!--        发起提现-->
        <div v-if="showCash == true">
            <div  style="cursor: pointer;width: 260px;color: #3d97d4;line-height: 50px;text-align: center;margin-left: 20px;">
                <el-input type="number" placeholder="输入金额" v-model="payAmt" clearable></el-input>
            </div>
            <div style="margin-left: 20px;margin-top: 20px;">
                <el-button  type="primary" @click="alpplyBankCarCash">确定提现</el-button>
            </div>
        </div>

        <!--        结算账户详情-->
        <div>
            <el-dialog :close-on-click-modal="false"
                    title="提示"
                    :visible.sync="dialogVisible"
                    width="30%"
                    :before-close="handleClose">
                <span>
                    <div style="line-height: 35px;margin-left: 20px;">所属银行:<span style="color:#F69403;">{{settleAccount.bankName}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">卡号:<span style="color:#F69403;">{{settleAccount.cardId}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">预留手机:<span style="color:#F69403;">{{settleAccount.telNo}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">绑定时间:<span style="color:#F69403;">{{settleAccount.updateTime}}</span></div>
                </span>
                <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
              </span>
            </el-dialog>
        </div>

<!--        支付订单列表-->
        <div v-if="createPay == true" style="width: 56%;height: 100%;float: left;margin-top: 20px;">
            <template>
                <el-table
                        :data="tableData"
                        style="width: 100%"
                        max-height="250">
                    <el-table-column
                            fixed
                            prop="createTime"
                            label="支付日期"
                            width="160">
                    </el-table-column>
                    <el-table-column
                            prop="orderNo"
                            label="订单号"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="bankName"
                            label="所属银行"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            prop="cardId"
                            label="银行卡号"
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="payAmt"
                            label="支付金额"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="status"
                            label="支付状态"
                            width="160">
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="120">
                        <template slot-scope="scope">
                            <el-button style="color: #3d97d4;" v-if="tableData[scope.$index].status == '未支付'" @click="clickPayOrder(scope.$index, tableData)" type="text" size="small">支付</el-button>
                            <el-button style="color: red;" v-if="tableData[scope.$index].status == '未支付'" @click="closeCorpOrder(scope.$index, tableData)" type="text" size="small">关单</el-button>
                            <el-button style="color: red;" v-if="tableData[scope.$index].status == '用户取消订单'"  type="text" size="small">订单已取消</el-button>
                            <el-button style="color: #FF9800;" v-if="tableData[scope.$index].status == '已支付'"  type="text" size="small">支付完毕</el-button>
                            <el-button @click.native.prevent="clickOrderInfo(scope.$index, tableData)" type="text" size="small" style="color: #3d97d4;;">
                                详情
                            </el-button>

                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>

        <!--       转账订单列表-->
        <div v-if="transferMoney == true" style="width: 56%;height: 100%;float: left;margin-top: 20px;">
            <template>
                <el-table
                        :data="tableData"
                        style="width: 100%"
                        max-height="250">
                    <el-table-column
                            fixed
                            prop="createTime"
                            label="支付日期"
                            width="160">
                    </el-table-column>
                    <el-table-column
                            prop="orderNo"
                            label="订单号"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="bankName"
                            label="所属银行"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            prop="cardId"
                            label="银行卡号"
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="payAmt"
                            label="支付金额"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="feeFlag"
                            label="手续费"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="commission"
                            label="佣金比例/金额"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            prop="status"
                            label="支付状态"
                            width="160">
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="120">
                        <template slot-scope="scope">
                            <el-button style="color: #3d97d4;" v-if="tableData[scope.$index].status == '未支付'" @click="clickPayOrder(scope.$index, tableData)" type="text" size="small">支付</el-button>
                            <el-button style="color: red;" v-if="tableData[scope.$index].status == '未支付'" @click="closeCorpTransOrder(scope.$index, tableData)" type="text" size="small">关单</el-button>
                            <el-button style="color: red;" v-if="tableData[scope.$index].status == '用户取消订单'"  type="text" size="small">订单已取消</el-button>
                            <el-button style="color: #FF9800;" v-if="tableData[scope.$index].status == '已支付'"  type="text" size="small">支付完毕</el-button>
                            <el-button @click.native.prevent="clickTransOrderInfo(scope.$index, tableData)" type="text" size="small" style="color: #3d97d4;;">
                                详情
                            </el-button>

                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>

        <!--       分账订单列表-->
        <div v-if="transFer == true" style="width: 56%;height: 100%;float: left;margin-top: 20px;">
            <template>
                <el-table
                        :data="tableData"
                        style="width: 100%"
                        max-height="250">
                    <el-table-column
                            fixed
                            prop="createTime"
                            label="支付日期1"
                            width="160">
                    </el-table-column>
                    <el-table-column
                            prop="orderNo"
                            label="订单号"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="bankName"
                            label="所属银行"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            prop="cardId"
                            label="银行卡号"
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="payAmt"
                            label="支付金额"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="feeFlag"
                            label="手续费"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="commission"
                            label="佣金比例/金额"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            prop="status"
                            label="支付状态"
                            width="160">
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="140">
                        <template slot-scope="scope">
                            <el-button style="color: #3d97d4;" v-if="tableData[scope.$index].status == 0" @click="clickPayOrder(scope.$index, tableData)" type="text" size="small">支付</el-button>
                            <el-button style="color: red;" v-if="tableData[scope.$index].status == 0" @click="closeCorpTransOrder(scope.$index, tableData)" type="text" size="small">关单</el-button>
                            <el-button style="color: red;" v-if="tableData[scope.$index].status == 3"  type="text" size="small">订单已取消</el-button>
                            <el-button style="color: #FF9800;" v-if="tableData[scope.$index].status == 1"  type="text" size="small">支付完毕</el-button>
                            <el-button style="color: #FF9800;" v-if="tableData[scope.$index].isPenny == 0 && tableData[scope.$index].status != 4 && tableData[scope.$index].status != 5" @click="jump(scope.$index, tableData)"  type="text" size="small">结算</el-button>
                            <el-button style="color: #FF9800;" v-if="tableData[scope.$index].isPenny == 1"  type="text" size="small">已结算</el-button>
                            <el-button style="color: red;" v-if="tableData[scope.$index].isPenny == 0 && tableData[scope.$index].status == 1" @click.native.prevent="refund(scope.$index, tableData)"  type="text" size="small">退款</el-button>
                            <el-button style="color: red;" v-if="tableData[scope.$index].isPenny == 0 && tableData[scope.$index].status == 4"  type="text" size="small">退款中</el-button>
                            <el-button style="color: red;" v-if="tableData[scope.$index].isPenny == 0 && tableData[scope.$index].status == 5"  type="text" size="small">已退款</el-button>
                            <el-button style="color: red;" v-if="tableData[scope.$index].isPenny == 0 && tableData[scope.$index].status == 6"  type="text" size="small">退款失败</el-button>
                            <el-button @click.native.prevent="clickCorpTransOrderInfo(scope.$index, tableData)" type="text" size="small" style="color: #3d97d4;;">
                                详情
                            </el-button>

                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>

        <!--       提现订单列表-->
        <div v-if="showCash == true" style="width: 56%;height: 100%;float: left;margin-top: 20px;">
            <template>
                <el-table
                        :data="tableData"
                        style="width: 100%"
                        max-height="250">
                    <el-table-column
                            fixed
                            prop="createTime"
                            label="提现日期"
                            width="160">
                    </el-table-column>
                    <el-table-column
                            prop="orderNo"
                            label="订单号"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="cashType"
                            label="结算类型"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            prop="realAmt"
                            label="提现金额"
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="feeAmt"
                            label="手续费"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="status"
                            label="提现状态"
                            width="160">
                    </el-table-column>
                </el-table>
            </template>
        </div>

        <!--        支付订单详情-->
        <div>
            <el-dialog :close-on-click-modal="false"
                    title="提示"
                    :visible.sync="dialogOrderInfo"
                    width="30%"
                    :before-close="handleClose">
                <span>
                    <div style="line-height: 35px;margin-left: 20px;">所属银行:<span style="color:#F69403;">{{orderInfo.bankName}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">卡号:<span style="color:#F69403;">{{orderInfo.cardId}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">订单号:<span style="color:#F69403;">{{orderInfo.orderNo}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">支付方式:<span style="color:#F69403;">{{orderInfo.payChannel}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">支付金额:<span style="color:#F69403;">{{orderInfo.payAmt}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">币种:<span style="color:#F69403;">{{orderInfo.currency}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">支付状态:<span style="color:#F69403;">{{orderInfo.status}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">创建时间:<span style="color:#F69403;">{{orderInfo.createTime}}</span></div>
                </span>
                <span slot="footer" class="dialog-footer">
                <el-button @click="dialogOrderInfo = false">取 消</el-button>
                <el-button type="primary" @click="dialogOrderInfo = false">确 定</el-button>
              </span>
            </el-dialog>
        </div>

        <!--        分账订单详情-->
        <div>
            <el-dialog :close-on-click-modal="false"
                    title="提示"
                    :visible.sync="dialogTransInfo"
                    width="30%"
                    :before-close="handleClose">
                <span>
                    <div style="line-height: 35px;margin-left: 20px;">所属银行:<span style="color:#F69403;">{{orderInfo.bankName}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">卡号:<span style="color:#F69403;">{{orderInfo.cardId}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">订单号:<span style="color:#F69403;">{{orderInfo.orderNo}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">支付方式:<span style="color:#F69403;">{{orderInfo.payChannel}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">支付金额:<span style="color:#F69403;">{{orderInfo.payAmt}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">币种:<span style="color:#F69403;">{{orderInfo.currency}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">支付状态:<span style="color:#F69403;">{{orderInfo.status}}</span></div>
                    <div style="line-height: 35px;margin-left: 20px;">创建时间:<span style="color:#F69403;">{{orderInfo.createTime}}</span></div>
                    <div v-if="orderInfo.isPenny == 1">
                        <div style="line-height: 35px;margin-left: 20px;">分账状态:<span style="color:#F69403;">已分账</span></div>
                        <div v-for="(item,index) in orderInfo.divMembers" :key="index">
                            <div style="line-height: 35px;margin-left: 20px;">姓名:<span style="color:#F69403;">{{item.realName}}</span></div>
                            <div style="line-height: 35px;margin-left: 20px;">金额:<span style="color:#F69403;">{{item.amount}}</span></div>
                        </div>
                        <div style="line-height: 35px;margin-left: 20px;">支付状态:<span style="color:#F69403;">{{orderInfo.status}}</span></div>
                    </div>
                        <div style="line-height: 35px;margin-left: 20px;">分账时间:<span style="color:#F69403;">{{orderInfo.updateTime}}</span></div>
                </span>
                <span slot="footer" class="dialog-footer">
                <el-button @click="dialogTransInfo = false">取 消</el-button>
                <el-button type="primary" @click="dialogTransInfo = false">确 定</el-button>
              </span>
            </el-dialog>
        </div>


    </div>
</template>

<script>
    export default {
        name: "corp_member_cash",
        computed:{
            loginUser() {
                return this.$store.state.user
            },
        },
        data(){
            return{
                accountBalance:[],
                settleAccount:{},
                dialogVisible: false,
                dialogOrderInfo:false,
                dialogTransInfo:false,
                payAmt:'',
                code:'',
                orderNo:'',
                //显示隐藏
                createPay:false,
                transFer:false,
                showCash:false,
                transferMoney:false,

                //表格数据
                tableData:[],

                mobile:"",
                user:[],
                orderInfo:{},
                radio: '',
                account:{}

            }
        },
        mounted() {
            this.queryCorpAccount();
        },
        methods:{
            //获取企业结算账户详情
            queryCorpAccount(){
                var that = this;
                var params = {};
                that.newApi.queryCorpAccount(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.accountBalance = res.data;
                    }
                })
            },
            handleClose(done) {
                done();
            },

            //支付表单显示/隐藏
            startcreatePay(){
                this.showCash = false;
                this.transFer = false;
                this.transferMoney = false;
                this.createPay = !this.createPay;
                this.getCorpOrderList();
            },

            //支付表格数据
            getCorpOrderList(){
                var that = this;
                var params = {};
                that.newApi.getCorpOrderList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.tableData = res.data;
                    }
                })
            },

            //支付按钮
            submitCreatePay(){
                var that = this;
                var params = {};
                params.payAmt = that.utils.keepTwoDecimalFull(that.payAmt);
                that.newApi.corpPaymentCreatePay(params).then((res) => {
                    if(res.isSuccess == 1){
                        this.$router.push({
                            name: 'corp_e_banking',
                            params: { formData: res.data}
                        })
                    }
                })
            },

            //继续支付按钮
            clickPayOrder(index,rows){
                //console.log(rows[index].transAssembledUrl);
                var s = rows[index].transAssembledUrl;
                var i = s.indexOf("<form");
                var m = rows[index].transAssembledUrl;
                var n = m.indexOf("</form");
                //网银支付页面:
                this.$router.push({
                    name: 'corp_e_banking',
                    params: { formData: s.substring(i,n+7)}
                })
            },

            //支付详情按钮
            clickOrderInfo(index,rows){
                var that = this;
                var params = {};
                params.orderNo = rows[index].orderNo;
                that.newApi.queryCorpPayment(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.orderInfo = res.data;
                        that.orderInfo.payAmt = that.utils.keepTwoDecimalFull(that.orderInfo.payAmt);
                        that.dialogOrderInfo = true;
                    }
                })
            },

            //支付关单按钮
            closeCorpOrder(index,rows){
                var that = this;
                var params = {};
                params.orderNo = rows[index].orderNo;
                that.newApi.closeCorpOrder(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.getCorpOrderList();
                    }
                })
            },

            //转账表单显示/隐藏
            starttransferMoney(){
                this.showCash = false;
                this.transFer = false;
                this.createPay = false;
                this.transferMoney = !this.transferMoney;
                this.getCorpTransferOrderList();
            },

            //手机号搜索用户
            checkedMoile(){
                var that = this;
                var params = {};
                params.phone = this.mobile;
                that.newApi.checkedMoile(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.user = res.data;
                    }
                })
            },
            //勾选转账人员
            agreeChange:function(val){
                var that = this;
                for (let i = 0;i < that.user.length;i++){
                    if(val == that.user[i].uuid){
                        let obj = {};
                        console.log(that.user[i]);
                        obj.uuid = that.user[i].uuid;
                        obj.nickName = that.user[i].nickName;
                        obj.bankName = that.user[i].bankName;
                        obj.realName = that.user[i].realName;
                        that.account = obj;
                    }
                }
            },

            //转账表格数据
            getCorpTransferOrderList(){
                var that = this;
                var params = {};
                that.newApi.getCorpTransferOrderList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.tableData = res.data;
                    }
                })
            },

            //转账详情按钮
            clickTransOrderInfo(index,rows){
                var that = this;
                var params = {};
                params.orderNo = rows[index].orderNo;
                params.payAmt = rows[index].payAmt;
                that.newApi.queryCorpTransPayment(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.orderInfo = res.data;
                        that.dialogOrderInfo = true;
                    }
                })
            },

            //转账按钮
            corpTransferMoneyDetermine(){
                var that = this;
                var params = {};
                params.uuid = that.account.uuid;
                params.payAmt = that.utils.keepTwoDecimalFull(that.payAmt);
                console.log(params);
                that.newApi.corpTransferMoneyDetermine(params).then((res) => {
                    if(res.isSuccess == 1){
                        if(res.isSuccess == 1){
                            this.$router.push({
                                name: 'corp_e_banking',
                                params: { formData: res.data}
                            })
                        }
                    }
                })
            },

            //转账关单按钮
            closeCorpTransOrder(index,rows){
                var that = this;
                var params = {};
                params.orderNo = rows[index].orderNo;
                that.newApi.closeCorpTransOrder(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.getCorpTransferOrderList();
                    }
                })
            },

            //分账表单显示/隐藏
            starttransFer(){
                this.showCash = false;
                this.createPay = false;
                this.transferMoney = false;
                this.transFer = !this.transFer;
                this.getCorpPointsOrderList();
            },

            //分账表格数据
            getCorpPointsOrderList(){
                var that = this;
                var params = {};
                that.newApi.getCorpPointsOrderList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.tableData = res.data;
                    }
                })
            },

            //分账详情
            clickCorpTransOrderInfo(index,rows){
                var that = this;
                var params = {};
                params.orderNo = rows[index].orderNo;
                params.payAmt = rows[index].payAmt;
                that.newApi.queryCorpTransPayment(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.orderInfo = res.data;
                        that.dialogTransInfo = true;
                    }
                })
            },

            //分账按钮
            divisionAccountsDetermine(){
                var that = this;
                var params = {};
                params.payAmt = that.utils.keepTwoDecimalFull(that.payAmt);
                that.newApi.divisionAccountsDetermine(params).then((res) => {
                    if(res.isSuccess == 1){
                        this.$router.push({
                            name: 'corp_e_banking',
                            params: { formData: res.data}
                        })
                    }
                })
            },

            //分账申请退款
            refund(index, rows){
                var that = this;
                var params = {};
                params.paymentId = rows[index].paymentId;
                params.orderNo = rows[index].orderNo;
                params.reverseAmt = that.utils.keepTwoDecimalFull(rows[index].payAmt);

                that.newApi.paymentReverseIndividual(params).then((res) =>{
                    if(res.isSuccess == 1){
                        that.queryPennyList();
                    }
                })
            },

            //结算跳转页面
            jump(index,rows){
                this.$router.push({
                    name: 'corp_div_members',
                    params: { formData: rows[index].orderNo}
                })
            },

            //提现表单显示/隐藏
            startCash(){
                this.transFer = false;
                this.createPay = false;
                this.transferMoney = false;
                this.showCash = !this.showCash;
                this.getCorpCashList();
            },

            //提现表格数据
            getCorpCashList(){
                var that = this;
                var params = {};
                that.newApi.getCorpCashList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.tableData = res.data;
                    }
                })
            },

            //提现按钮
            alpplyBankCarCash(){
                var that = this;
                var params = {};
                params.cashAmt = that.utils.keepTwoDecimalFull(that.payAmt);
                that.newApi.alpplyBankCarCash(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.payAmt = "";
                    }
                })
            },

        }
    }
</script>

<style scoped>

</style>
